import { Component, FormEvent } from "react";
import { PagePropCommonDocument } from "../../../modules/views/pages/pageProps";
import "./sign-in.css";
import {
  setGlobalAuthData,
  GlobalLocalStorages,
  getGlobalAuthData,
  GlobalStates,
} from "../../../config/global";
import Services from "../../../services/index";
import Item from "antd/lib/list/Item";
import { socket } from "app/socket-provider";
import { Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { grey } from "@mui/material/colors";

type PageState = {
  focusElement: any;
  keyboardOn: boolean;
  selectedItem: string;
  numpadOn: boolean;
  error: string;
  formData: {
    branchId: string;
    password: string;
  };
};

type PageProps = {} & PagePropCommonDocument;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: 1,
});

class PageSignIn extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      focusElement: "",
      keyboardOn: false,
      selectedItem: "",
      numpadOn: false,
      error: "",
      formData: {
        branchId: "",
        password: "",
      },
    };
  }

  from = (this.props.router.location.state as any)?.from?.pathname || "/";

  sets() {
    localStorage.removeItem("caseId");

    const focusElement = (): void => {
      window.addEventListener("focusin", (e: any) => {
        if (e.target.tagName === "INPUT") {
          this.setState({
            keyboardOn: true,
            focusElement: e.target,
          });
        } else {
          this.setState({
            keyboardOn: false,
          });
        }
      });
    };

    focusElement();
  }

  onChange = (input: any, setFieldValue: any) => {
    if (document.activeElement) {
      const activeElement = document.activeElement as HTMLInputElement;

      if (activeElement.tagName === "INPUT") {
        this.setState({
          focusElement: document.activeElement,
        });
        if (!(typeof activeElement.value === "string")) return;
        if (this.state.focusElement === false) {
          activeElement.value = input;
        } else {
          this.state.focusElement.value = input;
        }
      } else {
        this.setState({
          keyboardOn: true,
        });
        this.state.focusElement.value = input;
      }

      setFieldValue(
        this.state.focusElement.name,
        this.state.focusElement.value
      );
    }
  };

  componentDidMount() {
    if (GlobalStates.firstNavigate) {
      window.location.reload();
      GlobalStates.firstNavigate = false;
    }
    this.sets();
  }

  numpadOnClick = (e: any) => {
    let element = document.querySelector(
      this.state.selectedItem
    ) as HTMLInputElement;
    if (e.target.value == "remove") {
      element.value = element.value.slice(0, -1);
      if (this.state.selectedItem == "#password") {
        this.setState({
          formData: {
            branchId: this.state.formData.branchId,
            password: element.value,
          },
        });
      } else if (this.state.selectedItem == "#customBranchId") {
        this.setState({
          formData: {
            branchId: element.value,
            password: this.state.formData.password,
          },
        });
      }

      return;
    }
    if (e.target.value == "clear") {
      element.value = "";
      if (this.state.selectedItem == "#password") {
        this.setState({
          formData: {
            branchId: this.state.formData.branchId,
            password: element.value,
          },
        });
      } else if (this.state.selectedItem == "#customBranchId") {
        this.setState({
          formData: {
            branchId: element.value,
            password: this.state.formData.password,
          },
        });
      }
      return;
    }
    if (this.state.selectedItem == "#password") {
      this.setState({
        formData: {
          branchId: this.state.formData.branchId,
          password: this.state.formData.password + e.target.value,
        },
      });
      return;
    }
    if (this.state.selectedItem == "#customBranchId") {
      this.setState({
        formData: {
          branchId: this.state.formData.branchId + e.target.value,
          password: this.state.formData.password,
        },
      });
      element.value += e.target.value;
      return;
    }
  };

  Numpad = () => {
    if (true) {
      return (
        <div className="numpad">
          <div className="row">
            <button
              value={1}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              1
            </button>
            <button
              value={2}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              2
            </button>
            <button
              value={3}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              3
            </button>
          </div>
          <div className="row">
            <button
              value={4}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              4
            </button>
            <button
              value={5}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              5
            </button>
            <button
              value={6}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              6
            </button>
          </div>
          <div className="row">
            <button
              value={7}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              7
            </button>
            <button
              value={8}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              8
            </button>
            <button
              value={9}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              9
            </button>
          </div>
          <div className="row">
            <button
              value={"remove"}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              Sil
            </button>
            <button
              value={0}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              0
            </button>
            <button
              value={"clear"}
              onClick={(e) => this.numpadOnClick(e)}
              type="button"
            >
              Sıfırla
            </button>
          </div>
        </div>
      );
    }
  };

  handleChange(event: React.ChangeEvent<any>) {
    this.setState((state: PageState) => {
      let value: any = null;
      value = event.target.value;
      // @ts-ignore
      state.formData[event.target.name] = value;
      return state;
    });
  }

  onSubmit(event: FormEvent) {
    event.preventDefault();
    let value = document.querySelector("#customBranchId") as HTMLInputElement;
    Services.Post.signIn({
      branch_custom_id:
        GlobalLocalStorages.User.get.branchId ||
        this.state.formData.branchId ||
        value.value,
      password: this.state.formData.password,
    }).then((resData) => {
      if (resData.status) {
        let user = resData.data.user;
        window.localStorage.setItem(
          "branchCustomId",
          GlobalLocalStorages.User.get.branchId ||
            this.state.formData.branchId ||
            value.value
        );
        setGlobalAuthData({
          user: {
            branchId: user.branchId,
            branchTitle: user.branchTitle,
            branch_custom_id: user.branch_custom_id,
            name: user.name,
            lastname: user.lastname,
            permissions: user.permissions,
            role: user.role,
            bannedDevices: user.bannedDevices,
          },
        });
        let data = { userId: user._id, socketId: socket.id };
        socket.emit("pair", data);
        GlobalLocalStorages.Token.set(
          resData.data.accessToken,
          resData.data.refreshToken
        );
        GlobalLocalStorages.User.set(getGlobalAuthData().user.branchId);
        this.props.router.navigate("/", { replace: true });
      }
    });
  }

  render() {
    const bgImage = localStorage.getItem("background-image");
    return (
      <div className="flex items-center w-full h-full">
        <div
          className="w-1/2 h-full bg-cover bg-center"
          style={{
            backgroundImage: bgImage
              ? `url(${bgImage})`
              : "url(https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2574&q=80)",
          }}
        >
          <div className="h-full p-3 bg-black bg-opacity-50"></div>
        </div>
        <div className="flex items-center justify-center w-1/2">
          <form onSubmit={(e) => this.onSubmit(e)} className="w-2/3">
            <img
              src="https://manager.digigarson.com/static/logos/main-logo.png"
              className="mx-auto w-2/3"
            />
            <div className="mt-8 space-y-4">
              <div>
                <label
                  htmlFor="branch"
                  className="block text-sm font-medium text-white"
                >
                  {this.props.router.t("branch-code")}
                </label>
                <input
                  type="text"
                  name="branchId"
                  id="customBranchId"
                  placeholder="100000"
                  onClick={() => {
                    this.setState({
                      selectedItem: "#customBranchId",
                      numpadOn: true,
                    });
                  }}
                  onChange={(e) => this.handleChange(e)}
                  defaultValue={
                    window.localStorage.getItem("branchCustomId") ||
                    this.state.formData.branchId
                  }
                  className="mt-1 focus:ring-ebony focus:border-ebony block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  {this.props.router.t("password")}
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="******"
                  onClick={() =>
                    this.setState({ selectedItem: "#password", numpadOn: true })
                  }
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.formData.password}
                  className="mt-1 focus:ring-ebony focus:border-ebony block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <this.Numpad />
              </div>
              <button
                type="submit"
                className="min-h-[46px] group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ebony focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ebony"
              >
                {this.props.router.t("submit")}
              </button>
            </div>
            <button
              onClick={() =>
                window.require("electron").ipcRenderer.send("exit")
              }
              type="button"
              className="group relative w-1/2 flex justify-center mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ebony focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ebony"
            >
              {this.props.router.t("desktop")}
            </button>
            <Stack alignItems="center" sx={{ marginTop: "1rem" }}>
              <Button
                component="label"
                sx={{
                  color: "#fff",
                  backgroundColor: "#282C34",
                  "&:hover": {
                    backgrodunColor: "#282C34",
                  },
                  margin: "auto",
                  width: "50%",
                }}
                className="group relative w-1/2 flex justify-center mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ebony focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ebony"
                startIcon={<CloudUploadIcon />}
              >
                {this.props.router.t("change-background")}
                <VisuallyHiddenInput
                  onChange={(e: any) => {
                    const file = e.target.files?.[0];
                    const reader = new FileReader();
                    if (file) {
                      reader.onloadend = () => {
                        const base64String = reader.result as string;
                        localStorage.setItem("background-image", base64String);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  type="file"
                  accept="image/*"
                />
              </Button>
            </Stack>
            <button
              className="group relative w-1/2 flex justify-center mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ebony focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ebony"
              onClick={() => window.location.reload()}
            >
              {this.props.router.t("refresh")}
            </button>{" "}
            <p className="refreshText">
              {this.props.router.t("keyboard-refresh")}
            </p>
          </form>
        </div>
      </div>
    );
  }
}

export default PageSignIn;
