export const payment_methods = {
  1: "pos_bank",
  2: "pos_cash",
  3: "app_payment",
  6: "tick",
  7: "sodexo",
  8: "smart",
  9: "winwin",
  10: "multinet",
  11: "setcard",
  12: "metropol",
  13: "edended",
  14: "tips",
  15: "jetKurye",
  16: "not-payable",
  17: "migros-hemen",
  18: "getir-online",
  19: "trendyol",
  20: "yemek-sepeti",
  33: "bank",
};
